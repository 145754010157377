import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@cpq-environments/environment";
import { Observable } from "rxjs";
import { MSALService } from "@cpq-app/shared/services/msal.service";

const GASCOOLER_API_PATH = {
  GUNTNER: "guntner",
  GASCOOLER: "gasCooler",
  FILE: "file",
};

export enum GasCoolerVendors {
  Guntner = 'guntner',
}
export interface GuntnerParams {
  productId: string
  coolerType: string
  refrigerantType: string
  electricalType: string
  finMaterial: string
  stateLocation: string
  adiabaticOverride: boolean
  latitude: number
  longitude: number
  ambientTemp: number
  lowSaturationSuctionTemp: number
  medSaturationSuctionTemp: number
  lowTempRequiredLoad: number
  medTempRequiredLoad: number
  percentCapacityGap: number
}

export interface guntnerResult {
  errorCode?: number
  errorMessage?: string
  modelList?: ModelList[]
}

export interface ModelList {
  model: number
  numberOfPasses: number
  ratedTHR: number
  numberOfUnits: number
  airFlow: number
  switchPoint: number
  cO2MassFlow: number
  pressureDrop: number
  numberOfFans: number
  fanPowerIn: number
  fanPowerOut: number
  fla: number
  mca: number
  mocp: number
  fanVelocity: number
  soundAt3ft: number
  soundAt30ft: number
  soundPowerLevel: number
  finMaterial: number
  coilVolume: number
  mop: number
  numberOfAnchors: number
  height: number
  width: number
  length: number
  shippingWeight: number
  operatingWeight: number
  numberOfCoilInletConnection: number
  coilInletConnectionDiameter: number
  numberOfCoilOutletConnection: number
  coilOutletConnectionDiameter: number
  inletHeaderDiameter: number
  outetHeaderDiameter: number
  waterInletConnectionDiameter: number
  waterOutletConnectionDiameter: number
  minimumWaterPressure: number
  maximumWaterPressure: number
  evaporationRateAtDesign: number
  designWaterFlow: number
  maximumWaterFlow: number
  unitPrice: number
  optionPrice: OptionPrice
  drawingName: string
}

export interface OptionPrice {
  streamers: number
  hingedFanPanels: number
  vibrationDampers: number
  finAndPadGuard: number
  repairSwitches: number
  dryTypeTransformer600VTo480V: number
  autotransformer600VTo480V: number
  baCnetMSTP: number
  baCnetIP: number
  modbusRTU: number
  modbusTCPIP: number
  ethernetIP: number
  handrailAndLadderadder: number
  crnCertification: number
};

@Injectable({ providedIn: "root" })
export class gasCoolerService {
  constructor(private http: HttpClient, private _msalService: MSALService) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  getGutnerGasCooler(options: GuntnerParams): Observable<guntnerResult> {
    const url = this.gasCoolerUrl(GASCOOLER_API_PATH.GUNTNER);
    return this.http.post<guntnerResult>(url, options, { withCredentials: true });
  }
  getGasCoolerFileDownload(fileName: string, vendor: GasCoolerVendors) {
    let token = this._msalService.getTokenId();
    const fileUrl = this.gasCoolerUrl(vendor, GASCOOLER_API_PATH.FILE, fileName);
    return this.downloadGasCoolerFile(token, fileUrl);
  }

  downloadGasCoolerFile(token: string, fileUrl: string) {
    const pdfUrl = fileUrl;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    this.http.get(pdfUrl, { headers, responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      window.open(downloadUrl, '_blank');
    });
  }
  downloadDrawing(fileUrl: string){
    let drawingUrl = this.gasCoolerUrl('drawing');
    const params = new HttpParams().set('url',fileUrl );
    this.http.get(drawingUrl,{params, responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'image/jpeg' });
      const imageUrl = window.URL.createObjectURL(blob);
      window.open(imageUrl, '_blank');
    },
      (error) => {
        console.error('Error fetching PDF:', error);
      }
    );
  }
  downloadGasCoolerBACFile(fileUrl: string) {
    if (fileUrl) {
      this.http.get(fileUrl, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      },
        (error) => {
          console.error('Error fetching PDF:', error);
        }
      );
    }
  }

  /**
   * To get the URL for QuoteSummary system proposal of format type PDF
   * @param quoteId required `String` as the id of the target quote
   */
  getGasCoolerFileUrl(fileName: string, vendor: GasCoolerVendors) {
    const fileUrl = this.gasCoolerUrl(vendor, GASCOOLER_API_PATH.FILE, fileName);
    return fileUrl;
  }

  gasCoolerUrl(...args: string[]): string {
    let url = `${this.backendUrl}/${GASCOOLER_API_PATH.GASCOOLER}`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += "/" + args[i];
      }
    }

    return url;
  }
}
