// for prod replace `environment.ts` with `environment.cpq.ts` in deploy/profile/CPQTWGDEV folder
import { InjectionToken } from '@angular/core';
export const environment = {
  production: true,
  version: '0.0.0.0',
  B2CConfigs: {
    BackendURL: 'https://configure.hillphoenix.com/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'http://cpqvws-prod.centralus.cloudapp.azure.com/api',
    b2cScopes: 'https://cxportalprod.onmicrosoft.com/e73d5bbf-e9e0-4358-8468-3cd39b9a64a6/user.read',
    clientId: 'e73d5bbf-e9e0-4358-8468-3cd39b9a64a6',
    isPolicy: true,
    redirectUrl: 'https://configure.hillphoenix.com',
    superAdminOid: 'a24129ff-9321-495f-8bef-b35325ec88f5',
    tenant: 'cxportalprod.onmicrosoft.com',
    authURL: "https://cxportalprod.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'B2C_1A_signup_signin-CPQ-TWG',
      VWS: 'B2C_1A_cxportal_signin_policy_VWS',
      DFR: 'b2c_1a_cxportal_prod_signin_policy_dfrhill'
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordReset-CPQ-TWG',
      VWS: 'B2C_1A_PasswordResetVWS',
      DFR: 'B2C_1A_PASSWORDRESETDFRHILL'
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_ProfileEditPasswordChange-CPQ-TWG',
      VWS: 'B2C_1A_PasswordChangeVWS',
      DFR: 'B2C_1A_PasswordChangeDFR'
    },
    b2cLoginURL: 'https://cxportalprod.b2clogin.com',
    passwordChangeSuccessUrl: 'https://configure.hillphoenix.com/changePasswordSuccess'
  },
  cxPortal: {
    baseUrl: 'https://dfrportal.hillphoenix.com',
  },
  fpx: {
    imageBaseURL: 'https://sbx.fpx.com',
    BlobDFRStorageURL: 'https://dfrco2devstg.blob.core.windows.net/dfrco2',
    energyAPI: 'https://dfr-co2-dev-webapp02.azurewebsites.net/api'
  },
  cds: {
    endpoint: 'qa',
    viewerUrl: 'https://qa.product-config.net/cfg/cpqvisualization/opw/js/cds-viewer-1.0.1.js',
    proxyApiPath: '/cpq/cds',
	  ImageBaseUrl:'https://dpk3n3gg92jwt.cloudfront.net'												   
				
  },
  googleApiKey: {
    key: 'AIzaSyDUunzfde7yEFl8bm3R3JRqPyuS-2MtMcg',
  },
   salesForce: {
    dfrAccount: '0011N00001kkC3XQAU',
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
