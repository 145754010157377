<div class="card product-card">
    <div class="card-body">
        <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
            type="line-scale-party" [fullScreen]="true" [name]="MODEL_CARD_SPINNER">
            <p class="login-spinner spinner mt-3">Please wait...</p>
        </ngx-spinner>

        <div [ngClass]="{'radio-with-Icon-na': product.Selected === true}">
            <div class="d-flex justify-content-between"><span class="card-title">{{product.ProductId}}</span>
                <div class="select-product-block radio-with-Icon"
                    [ngClass]="{ 'select-margin': product?.type === productTypes?.Co2Model }">
                    <div>
                        <div class="radioOption-Item"
                            [ngClass]="{'radio-with-Icon-internal': product.Selected === true}"
                            [ngbTooltip]="ToolTipAddToRack">
                            <ng-template #ToolTipAddToRack>
                                <small>{{product_ToolTip.addtoRackToolTip}}</small>
                            </ng-template>
                            <input type="radio" name="ProductSelect" id="{{product?.ProductId}}" value="true"
                                class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false"
                                (change)="selectProduct(product,product?.ProductId)"
                                [checked]=" product.Selected === true">
                            <label for="{{product?.ProductId}}"
                                [ngClass]="{'compare-selected-radio': product.Selected === true}">
                                <span *ngIf="product.Selected === true">Selected</span>
                                <span *ngIf="product.Selected !== true">Select</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img class="product-img" [src]="product?.imageUrl"
                onerror="this.onerror=null;this.src='../assets/images/dfr/no_image.png';" alt="product_image">
        </div>
        <hr class="line-product">
        <div class="row product-info">
        </div>
        <ng-template #ToolTipPrice>
            <small>{{product_ToolTip.priceTooltip}}</small>
        </ng-template>
        <!-- <div class="row product-info">
            <div class="col-lg-6 product-info-term">Price</div>
            <div class="col-lg-6 product-info-value-icons">
                <app-custom-icon [ngbTooltip]="ToolTipPrice" [colorClass]="'gold'" [rating]="product.listPrice"
                    [icon]="icons.price">
                </app-custom-icon>
            </div>
        </div> -->
        <!-- <ng-template #ToolTipEnergyUsage>
            <small>{{product_ToolTip.energyUsageToolTip}}</small>
        </ng-template>
        <div class="row product-info">
            <div class="col-lg-6 product-info-term">Energy Usage</div>
            <div class="col-lg-6 product-info-value-icons">
                <app-custom-icon [ngbTooltip]="ToolTipEnergyUsage" [colorClass]="'blue'" [rating]="product.energyUsage"
                    [icon]="icons.energy">
                </app-custom-icon>
            </div>
        </div> -->
        <!-- <ng-template #ToolTipInstallation>
            <small>{{product_ToolTip.installationCostTooltip}}</small>
        </ng-template>
        <div class="row product-info">
            <div class="col-lg-6 product-info-term">Installation Cost</div>
            <div class="col-lg-6 product-info-value-icons">
                <app-custom-icon [ngbTooltip]="ToolTipInstallation" [colorClass]="'dark-gray'"
                    [rating]="product.installCost" [icon]="icons.installation">
                </app-custom-icon>
            </div>
        </div> -->
        <ng-template #ToolTipEnvironment>
            <small>{{product_ToolTip.environmentFriendlyToolTip}}</small>
        </ng-template>
        <!-- <div class="row product-info">
            <div class="col-lg-6 product-info-term">Environmental Friendly</div>
            <div class="col-lg-6 product-info-value-icons">
                <app-custom-icon [ngbTooltip]="ToolTipEnvironment" [colorClass]="'green'"
                    [rating]="product.environmentalImpact" [icon]="icons.environment"></app-custom-icon>
            </div>
        </div> -->
        <!-- <div class="row product-info">
            <div class="col-lg-6 product-info-term">Power Requirements</div>
            <div class="col-lg-6 product-info-value">{{product.powerRequirement}}</div>
        </div> -->
        <div class="row product-info">
            <div class="col-lg-6 product-info-term">Refrigerant Type</div>
            <div class="col-lg-6 product-info-value">{{product.RefrigerantType}}</div>
        </div>
        <div class="row product-info">
            <div class="col-lg-6 product-info-term"
                [textTransformer]="{ modelType: product?.type, text:mtTotalLoad === 0 ? 'LT Max Capacity' : 'MT Max Capacity' }">
                Medium Temp Capacity (at design conditions)</div>
            <div class="col-lg-6 product-info-value">
                {{product.MedTempCompressorCapacity}}
            </div>
        </div>
        <div class="row product-info"
            *temperatureVisibility="{ modelType: product?.type, ltValue:product?.MedTempExcessPercent }">
            <div class="col-lg-6 product-info-term"
                [textTransformer]="{ modelType: product?.type, text: 'MT Spare Capacity' }">MT Spare Capacity Percent
            </div>
            <div class="col-lg-6 product-info-value">{{product?.MedTempExcessPercent | number:'1.2-2'}}%</div>
        </div>

        <div class="row product-info">
            <div class="col-lg-6 product-info-term"
                [textTransformer]="{ modelType: product?.type, text:ltTotalLoad === 0 ? 'MT Min Capacity' : 'LT Min Capacity' }">
                Low Temp Capacity (at design conditions)</div>
            <div class="col-lg-6 product-info-value">
                {{product.LowTempCompressorCapacity}}
            </div>
        </div>
        <div class="row product-info"
            *temperatureVisibility="{ modelType: product?.type, ltValue:product?.LowTempExcessPercent }">
            <div class="col-lg-6 product-info-term"
                [textTransformer]="{ modelType: product?.type, text: 'LT Spare Capacity' }">LT Spare Capacity Percent
            </div>
            <div class="col-lg-6 product-info-value">{{product?.LowTempExcessPercent | number:'1.2-2'}}%</div>
        </div>
        <div class="row product-info" *ngIf="!isEorUser">
            <div class="col-lg-6 product-info-term">Base Unit Price <mat-icon matSuffix class="basePrice_info"
                    matTooltipPosition="right" matTooltip='Discounted price base price for rack only.'>info</mat-icon>
            </div>
            <div class="col-lg-6 product-info-value">{{product?.BaseUnitPrice | currency: 'USD':true:'2.0'}}</div>
        </div>
    </div>

    <div class="col-lg-12 d-flex justify-content-between pb-3 align-self-end">
        <mat-checkbox class="example-margin"  (change)="selectProductToCompare($event,product)">Add to Compare </mat-checkbox> 
        <button type="button" class="dfr-btn-light large-btn" (click)="showProductDetails(product)">Product
            Details</button>
    </div>

</div>