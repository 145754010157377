<div class="product-selector">
  <ngx-spinner class="spinner-attributes" bdOpacity=3 bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#4054B2"
    type="line-scale-party" [fullScreen]="true" [name]="DESIGN_CRITERIA_SPINNER">
    <p class="login-spinner mt-3">Please wait...</p>
  </ngx-spinner>
  <div class="guided-selling-container">
    <div class="left" [hidden]="!isShowDesignCriteria">
      <div class="designcriteria-sub-header mt-1">Design Criteria <span class="icon"> <mat-icon
            class="mt-1">info</mat-icon></span></div>
      <div class="cds-config-options-design" id="cds-config-options-design"></div>
    </div>
    <div [ngClass]="{'closeDesignCriteria':!isShowDesignCriteria,'openDesignCriteria':isShowDesignCriteria}">
      <button *ngIf="!isShowDesignCriteria" mat-raised-button mat-icon-button (click)="onDesignCriteriaClick()"
        matTooltip="Expand"><mat-icon>
          keyboard_arrow_right</mat-icon></button>
      <button *ngIf="isShowDesignCriteria" mat-raised-button mat-icon-button (click)="onDesignCriteriaClick()"
        matTooltip="Collapse"> <mat-icon>
          keyboard_arrow_left</mat-icon></button>
      <div class="rotationDesignText" *ngIf="!isShowDesignCriteria">Design Criteria </div>
    </div>

    <div class="right" [ngClass]="{'collapseRight': !isShowDesignCriteria}">
      <div class="d-flex flex-row flex-wrap justify-content-between">
        <nav class="custom-breadcrumb" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">home</mat-icon>
              <a [routerLink]="['/']">Home</a>
            </li>
            <li class="breadcrumb-item"><mat-icon class="breadcrumb-icons">inventory</mat-icon><a [routerLink]="[]"
                (click)="
              goBack()">Project Details</a></li>
            <li class="breadcrumb-item active" aria-current="page"> <mat-icon
                class="breadcrumb-icons">edit</mat-icon>Model Selection</li>
          </ol>
        </nav>

        <!-- <button mat-flat-button color="primary" class="add-rack-btn" (click)="saveConfiguration()">
          Save Configuration
          &nbsp; <mat-icon>save_circle</mat-icon>
        </button> -->
      </div>

      <div class="d-flex flex-row justify-content-between ipadView mb-1">
        <div class="revision-name">
          {{revisionName}}
        </div>
        <div class="d-flex flex-row">
          <ng-template #ToolTipTemplate>
            <small>Revision is Expired.</small>
          </ng-template>
          <div class="rev-btn-wrap-inner mr-2">
            <app-proposal-report [reportData]="proposalResult" [QuoteId]="revisionId"
              [isDisable]="downloadReport"></app-proposal-report>
          </div>

          <div class="add-rack-btn-container">
            <button mat-flat-button color="primary" class="add-rack-btn" (click)="addTabRack()">
              Add Rack
              &nbsp; <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="cds-config-options"
        [ngClass]="{'closeRackCriteria Rack-cont-Out':!this.isShowDesignCriteria,'openRackCriteria  Rack-cont':isShowDesignCriteria}"
        id="cds-config-options-rack-1"></div>
      <mat-card class="card-container">
        <mat-card-content>
          <mat-tab-group class="demo-tab-group" #tabGroup (selectedTabChange)="tabChange($event)">
            <mat-tab [label]="rack.Name" *ngFor="let rack of availableRacks; index as i;" id="ngb-panel-rack-{{i}}">
              <ng-template mat-tab-label>
                <div class="d-flex" (click)="userClickedTab()">
                  {{rack?.Name}}
                  <mat-icon class="example-tab-icon pr-3 tab-close" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                </div>
              </ng-template>
              <mat-menu #menu="matMenu" [ngClass]="{'wrapper_readOnly':!true}">
                <span [ngClass]="{'disablePointerEvents':!true}">
                  <button mat-menu-item (click)="openRackNameDialog(rack)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Rack Name</span>
                  </button>
                  <button mat-menu-item (click)="copyRack(rack)">
                    <mat-icon>content_copy</mat-icon>
                    <span>Duplicate Rack</span>
                  </button>
                  <button mat-menu-item *ngIf="rack?.Id!==primaryRack" (click)="deleteRack(rack?.Id)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Rack</span>
                  </button>
                </span>
              </mat-menu>
              <div class="rack-container">
                <div class="inner-container-right" [style.padding-left.px]="this.isShowDesignCriteria?310:310">
                  <div class="container-results">
                    <div *ngIf="!isConfigurationChanged && this.availableProducts?.length > 1"
                      [ngClass]="{'disabledNoOfCasesDiv': selectedCompareProducts?.length<=1}" class="float_compare"
                      title="Compare products" (click)="getCompareProducutData()">
                      <span class="compare-text">Compare Products</span>
                    </div>
                    <div class="width_results d-flex flex-wrap justify-content-start">
                      <div class="fetch_P_label" *ngIf="isConfigurationChanged" >Please select/input all desired rack
                        criteria options, before continuing with retrieving
                        products. <div class="d-flex flex-wrap justify-content-center AOCT_Note">Note: Air Over Coil
                          Temperature value will update after Retrieving Products.</div>
                        <div class="d-flex flex-wrap justify-content-center"><button class="button_fetch_product"
                            (click)="fetchProducts()"><span>Retrieve
                              Products </span></button>
                        </div>
                      </div>
                    </div>
                    <div class="contact-tps mb-2" *ngIf="!this.isInternalUser">
                      <button mat-fab color="primary" [disabled]="!canEdit" class="add-rack-btn" (click)="contactTps()"
                        #tooltip="matTooltip" matTooltip="Contact TPS">
                        <mat-icon>contact_support</mat-icon>
                      </button>
                    </div>
                    <ng-container *ngIf="availableProducts?.length > 0 && !isConfigurationChanged ">
                      <div>
                        <div>
                          <div class="d-flex flex-row flex-wrap justify-content-start">

                            <mat-card class="custom" *ngFor="let product of availableProducts index as i"
                              id="'product'+{{i}}">
                              <app-product-card [id]="'product'+i" [product]="product"
                                [compairProducts]="selectedCompareProducts"
                                (productSelectEvent)="onProductSelect($event)"
                                (productDetailEvent)="productDetail($event)"
                                (productsCompareEvent)="productsCompare($event)">
                              </app-product-card>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="this.availableProducts?.length < 1 && enableNoMatchFound"
                      class="side-gutter no-match-min-h">
                      <span class="sorry-no-matches">No models found for provided inputs. Please <a
                          href="javascript:void(0);" (click)="contactTps()">contact TPS.</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="save-version-section" [ngClass]="{'wrapper_readOnly':!canEdit}">
    <div class="d-flex flex-row justify-content-center action-section" [ngClass]="{'disablePointerEvents':!canEdit}">
      <div class="d-flex flex-column mb-3 child-tpa" *ngIf="!this.isInternalUser">
        <div class="p-2 bd-highlight"><span class="action-text">Can’t find the
            right
            product?</span></div>
        <div class="p-2 bd-highlight"><button mat-flat-button color="primary" class="add-rack-btn"
            title="Tip: Try changing your design criteria" (click)="contactTps()">
            Save Version and Contact Technical product support</button></div>
      </div>
    </div>
  </div>
</div>