import { Injectable } from '@angular/core';
import { CartService, CpqObjectType, CpqOpportunity, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { format, add, differenceInCalendarDays } from 'date-fns';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DfrCpqOpportunity, DfrCpqQuote } from './DFR.interfaces.service';
import { difference, toUpper } from 'lodash';
import { CpqJob } from '../Cpq.interfaces.service';



const DATE_FMT = 'yyyy-MM-dd';
const DATE_STRING_FMT = 'yyMMddHHmmssSSS';

const enum CPQ_OPERATIONS_SERVICE_CONSTANTS {
    USER_NAME = 'username',
    CURRENCY_USD = 'USD',
    JOB = 'Job',
    QUOTE = 'Revision'
}
const enum CPQ_OPERATIONS_PROJECT {
    PROJECT_NAME = 'DFRCO2 Project',
    SOURCE = 'CO2',
    REVISION_NAME = 'New Quote',
    STATE = 'Active'
}

const enum PROJECT_TYPE {
    START_NEW_PROJECT = 'Start New Project',
    QUICK_SEARCH_PROJECT = 'Quick Search Project'
}

interface DfrCpqNewQuote extends Pick<any,
    'Name' | 'RFQ_Due_Date__c' | 'RFQ_Receive_Date__c' | 'ShipmentDate__c' | 'isExpertMode__c' | 'IsPrimary'> {
    OpportunityId: string;
}

export abstract class ExpiryCssClass {
    static readonly EXPIRED = 'expire-expired';
    static readonly DANGER = 'expire-danger';
    static readonly WARNING = 'expire-warning';
    static readonly MODERATE = 'expire-moderate';
    static readonly NORMAL_SINGLE_DIGIT = 'expire-normal-single-digit';
    static readonly NORMAL = 'expire-normal';
    static readonly NORMAL_TRIPLE_DIGIT = 'expire-normal-triple-digit';
}

export interface DfrCpqNewOpportunity extends Pick<any, 'Name' | 'Ambient_Temp__c' | 'Zip_Code__c' | 'application__c' | 'storeType__c' | 'Source__c' | 'Status__c' | 'AccountId' | 'drop_ship_address_line_1__c' | 'latitude__c' | 'longitude__c' | 'AmbientTempJSON__c' | 'stationName__c' | 'UnitType' | 'ChildAccountId'> { }



@Injectable({
    providedIn: 'root'
})

export class CpqOperations {
    revision = new BehaviorSubject(null);
    productsInRevisions = new BehaviorSubject(null);

    public changeArchiveProjectStatus = new Subject<void>();
    constructor(
        private cartService: CartService,
        private modalService: NgbModal,
    ) { }


    getUserName(): string {
        const [userName] = sessionStorage.getItem(CPQ_OPERATIONS_SERVICE_CONSTANTS.USER_NAME).split('@');
        return userName;
    }

    getDateString(dateFormat: string, date = new Date()): string {
        return format(date, dateFormat);
    }

    addDaysToDate(numberOfDays: number, date = new Date()): Date {
        if (isNaN(numberOfDays)) {
            return;
        }
        return add(date, { days: numberOfDays });
    }

    getCpqObjectName(ObjectType: string): string {
        const todayDateString = this.getDateString(DATE_STRING_FMT);
        return `${this.getUserName()} ${ObjectType} ${todayDateString}`;
    }

    getNewProjectObject(projectPayload: DfrCpqNewOpportunity): DfrCpqNewOpportunity {
        console.log(projectPayload);
        return {
            Name: projectPayload['Name'] || CPQ_OPERATIONS_PROJECT.PROJECT_NAME,
            Ambient_Temp__c: projectPayload['Ambient_Temp__c'],
            Zip_Code__c: projectPayload['Zip_Code__c'],
            application__c: projectPayload['application__c'],
            storeType__c: projectPayload['storeType__c'],
            Source__c: CPQ_OPERATIONS_PROJECT.SOURCE,
            Status__c: CPQ_OPERATIONS_PROJECT.STATE,
            AccountId: projectPayload['accountSelection'],
            drop_ship_address_line_1__c: projectPayload['drop_ship_address_line_1__c'],
            latitude__c: projectPayload['latitude__c'],
            longitude__c: projectPayload['longitude__c'],
            AmbientTempJSON__c: projectPayload['AmbientTempJSON__c'],
            stationName__c: projectPayload['stationName__c'],
            UnitType: projectPayload['UnitType'],
            ChildAccountId: projectPayload['ChildAccountId']
        };
    }

    createDFRProject(projectPayload?: DfrCpqNewOpportunity): Observable<string> {
        const jobObject = this.getNewProjectObject(projectPayload);
        return new Observable<string>(observer => {
            const subscription = this.cartService.createObject(CpqObjectType.Opportunity, jobObject).subscribe({
                next: (data: string) => {
                    observer.next(data);
                },
                error: err => {
                    observer.error(err);
                }
            });
            return {
                unsubscribe: () => subscription.unsubscribe()
            };
        });
    }

    getNewQuoteObject(opportunityId: string, projectType?: boolean): DfrCpqNewQuote {
        const RFQDate = new Date();
        const ProjectType = projectType || false;
        return {
            OpportunityId: opportunityId,
            Name: this.getCpqObjectName(CPQ_OPERATIONS_SERVICE_CONSTANTS.QUOTE),
            RFQ_Due_Date__c: RFQDate.toISOString(),
            RFQ_Receive_Date__c: RFQDate.toISOString(),
            ShipmentDate__c: RFQDate.toISOString(),
            isExpertMode__c: ProjectType,
            IsPrimary: 1
        };
    }

    createDFRQuote(opportunityId: string, projectType: boolean): Observable<string> {
        if (!Boolean(opportunityId)) {
            return throwError('Opportunity ID required to create Quote');
        }
        const quoteObject = this.getNewQuoteObject(opportunityId, projectType);
        return new Observable<string>(observer => {
            const subscription = this.cartService.createObject(CpqObjectType.Quote, quoteObject).subscribe({
                next: (data: string) => {
                    observer.next(data);
                },
                error: err => {
                    observer.error(err);
                }
            });
            return {
                unsubscribe: () => subscription.unsubscribe()
            };
        });
    }

    getQuoteLineProducts(quoteId: string): Observable<any> {
        return this.cartService.getCpqObjects<any>(
            CpqQueryObjects.QuoteProducts,
            {
                Id: quoteId,
                addonFields: DfrCpqQuote.addOnFields.join(', '),
                resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            });
    }

    getProductsAssociatedWithQuotes(rootProductId: string): Observable<any> {
        const addOnFields = "modelName__c,mtTotalLoad__c,mtEvaporatorTemp__c,ltTotalLoad__c,ltEvaporatorTemp__c,imageName__c,ratedLoading__c,Total_Capacity__c";
        return this.cartService.getCpqObjects<any>(
            CpqQueryObjects.NestedProducts,
            {
                Id: rootProductId, // FIXME: ideally, we do not send string boolean,
                addonFields: addOnFields,
                resolveNames: 'false'
            });
    }



    getCPQOpportunityById(opportunityID: string): Observable<DfrCpqOpportunity[]> {
        return this.cartService.getCpqObjects<DfrCpqOpportunity>(
            CpqQueryObjects.Opportunities,
            {
                Id: opportunityID,
                addonFields: DfrCpqOpportunity.addOnFields.join(', '),
                resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            });
    }
    getQuoteInfo(quoteId: string): Observable<any> {
        return this.cartService.getCpqObjects<any>(
            CpqQueryObjects.Quote,
            {
                Id: quoteId,
                addonFields: DfrCpqQuote.addOnFields.join(', '),
                resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            });
    }
    getProjectInfo(projectId: string): Observable<any> {
        return this.cartService.getCpqObjects<any>(
            CpqQueryObjects.Opportunity,
            {
                Id: projectId,
                addonFields: DfrCpqOpportunity.addOnFields.join(', '),
                resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            });
    }

    returnExpire(record: any) {
        const difference = this.getDifferenceInCalendarDays(record);
        let value: number | string = difference;
        if (difference > 65) {
            value = '65+';
        } else if (difference < 0) {
            value = ''; // Passing empty value to apply No Entry class
        }
        return value;
    }

    getDifferenceInCalendarDays(record: any): number {
        let expirationDate: string;
        if (record?.PrimaryQuote) {
            expirationDate = record?.PrimaryQuote.ExpirationTime;
        }
        else {
            expirationDate = record?.ExpirationTime;
        }
        return differenceInCalendarDays(
            new Date(expirationDate), new Date());
    }

    returnExpireClass(record: any): string {
        const diff = this.returnExpire(record);
        try {
            if (diff === '') {
                return ExpiryCssClass.DANGER;
            } else if (diff === '65+') {
                return ExpiryCssClass.NORMAL_TRIPLE_DIGIT;
            } else if (diff >= 0 && diff <= 0) {
                return ExpiryCssClass.DANGER;
            } else if (diff > 1 && diff <= 10) {
                return ExpiryCssClass.MODERATE;
            } else if (diff > 1 && diff <= 10) {
                return ExpiryCssClass.NORMAL_SINGLE_DIGIT;
            } else {
                return ExpiryCssClass.NORMAL;
            }
        } catch (err) {
            console.log(err);
        }
    }

    returnTitleText(record: any) {
        const differences = this.getDifferenceInCalendarDays(record);
        let value = `Proposal will expire in ${differences} days`;
        if (differences <= 0) {
            value = `Proposal has expired.`;
        }
        else if (differences >= 10) {
            value = `Proposal is ready to download.`;
        }
        return value;
    }

    setCurrentRevision(revision) {
        this.revision.next(revision);
    }

    get currentRevision(): Observable<DfrCpqQuote> {
        return this.revision.asObservable();
    }

    setProductsInCurrentRevisions(productsInRevisions) {
        this.productsInRevisions.next(productsInRevisions);
    }

    get productsInCurrentRevisions() {
        return this.productsInRevisions.asObservable();
    }
}

