import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, CpqQueryObjects, QuoteWorkflowStatus, CpqObjectType } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { DatatableColumn, ExpiryCssClass, StatusCssClass, RowActions as TableRowActions, CursorCssClass } from '@cpq-app/shared/datatable/datatable';
import { CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CpqOperations } from '../cpqOperations.service';
import { DFRAclAccess, DfrCpqOpportunity, DfrCpqQuote, DfrJobs, ProjectStatus } from '../DFR.interfaces.service';
import { differenceInCalendarDays } from 'date-fns';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareComponent } from '../share/share.component';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
const DATE_FMT = 'yyyy-MM-dd';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  projects: DfrJobs[] = [];
  subscription$: Subscription[] = [];
  projectSubscription: Subscription;
  projectId: string;
  revisionId: string;
  dataLoading = false;
  currencyPipe: any;
  columnDefinitions$: Subject<DatatableColumn<CpqJob>[]>;
  isInternalUser: boolean;
  canEdit: boolean;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private dfrCpqOperations: CpqOperations,
    private loginService: LoginService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.fetchProject();
    this.isInternalUser = this.userService.checkLoggedInUserIsInternalUser();
    const colDef = this.columnDefinitionFactory();
    this.columnDefinitions$ = new BehaviorSubject<DatatableColumn<CpqJob>[]>(colDef);

    this.dfrCpqOperations.changeArchiveProjectStatus
      .subscribe({
        next: () => this.fetchProject()
      });

  }

  fetchProject() {
    this.dataLoading = true;
    this.projects = [];
    this.subscription$.push(this.cartService.getCpqObjects<DfrJobs>(
      CpqQueryObjects.Opportunities)
      .subscribe({
        next: (data: CpqJob[]) => {
          this.projects = [...this.projects, ...data];
          this.projects = this.projects.filter(x => {
            return x.Source__c === 'CO2' && x.Status__c === 'Active'
          }).sort((a, b) => Number(b?.ModifiedTime) - Number(a?.ModifiedTime));
          this.projects.forEach(project => {
            project["IsPreferred__c"] = this.convertStringToBoolean(project["IsPreferred__c"]);
          });
          this.projects = this.projects.sort((a, b) => Number(b.IsPreferred__c) - Number(a.IsPreferred__c));
          this.spinner.hide();
        },
        complete: () => {
          this.dataLoading = false;
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching project', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      }));
  }

  columnDefinitionFactory(): DatatableColumn<CpqJob>[] {
    const colData: DatatableColumn<CpqJob>[] = [
      {
        field: 'Name', header: 'Project Name', isPrimary: true,
        visibility: true, sortable: true, filterable: true,
        value: (project: DfrJobs) => project?.Name, handler: (record: CpqJob) => this.onClickView(record),
        className: () => CursorCssClass.ALLOW_CLICK
      },
      {
        field: 'IsPreferred__c', control: 'checkbox', header: 'Favorite',
        visibility: true, sortable: true,
        value: (project: DfrJobs) => project?.IsPreferred__c,
        handler: (project: DfrJobs) => this.onClickFavoriteProjectCheck(project),
        compare: (a: DfrJobs, b: DfrJobs) => Number(a.IsPreferred__c > b.IsPreferred__c) - Number(a.IsPreferred__c < b.IsPreferred__c)
      },
      {
        field: 'ModifiedTime', header: 'Last Update',
        initialSort: true, sortable: true, visibility: true,
        value: (project: DfrJobs) => this.datePipe.transform(project.ModifiedTime, DATE_FMT),
        compare: (a: CpqJob, b: CpqJob) => {
          const aD = new Date(a.ModifiedTime);
          const bD = new Date(b.ModifiedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'BudgetaryNumber', header: 'Quote Number',
        initialSort: true, sortable: true, visibility: true,
        value: (project: DfrJobs) => project.PrimaryQuote?.BudgetaryNumber,
        compare: (a: DfrJobs, b: DfrJobs) => Number(a.PrimaryQuote?.BudgetaryNumber > b.PrimaryQuote?.BudgetaryNumber) - Number(a.PrimaryQuote?.BudgetaryNumber < b.PrimaryQuote?.BudgetaryNumber)
      },
      {
        field: 'Owner', header: 'Creator',
        visibility: true, sortable: true, filterable: true,
        value: (project: DfrJobs) => project.Owner?.Name,
        compare: (a: CpqJob, b: CpqJob) => a.Owner?.Name?.localeCompare(b.Owner?.Name)
      },
      {
        field: 'Status__c', header: 'Status',
        visibility: true, sortable: true, filterable: false,
        value: (record: CpqJob) => '',
        className: (record: CpqJob) => this.dfrCpqOperations.returnExpireClass(record),
        titleText: (record: CpqJob) => this.dfrCpqOperations.returnTitleText(record),
        compare: (a: CpqJob, b: CpqJob) => {
          const aE = this.dfrCpqOperations.getDifferenceInCalendarDays(a);
          const bE = this.dfrCpqOperations.getDifferenceInCalendarDays(b);
          return Number(aE > bE) - Number(aE < bE);
        }
      },
      {
        field: 'actions', header: 'Actions', visibility: true, value: (record) => {
          const actionsArray = [
            { action: TableRowActions.VIEW, handler: () => this.onClickView(record) },
            { action: TableRowActions.COPY, handler: () => this.onClickCopy(record) }
          ];
          // if (this.isInternalUser) {
          //   const shareAction = { action: TableRowActions.SHARE, handler: () => this.onClickShare(record) };
          //   actionsArray.push(shareAction);
          // }

          return actionsArray;
        }
      }
    ];
    return colData;
  }
  returnStatusClass(project: DfrJobs): string {
    switch (project.Status__c) {
      case ProjectStatus.Active:
        return StatusCssClass.ACTIVE;

      case ProjectStatus.Closed:
        return StatusCssClass.CLOSE;

      case ProjectStatus.Completed:
        return StatusCssClass.CLOSE;

      case ProjectStatus.Abandoned:
        return StatusCssClass.CLOSE;

      default:
        return StatusCssClass.ACTIVE;
    }
  }

  returnExpireClass(record: CpqJob): string {
    const diff = this.returnExpire(record);
    try {
      if (diff === '') {
        return ExpiryCssClass.EXPIRED;
      } else if (diff === '99+') {
        return ExpiryCssClass.NORMAL_TRIPLE_DIGIT;
      } else if (diff >= 0 && diff <= 2) {
        return ExpiryCssClass.DANGER;
      } else if (diff > 2 && diff <= 4) {
        return ExpiryCssClass.WARNING;
      } else if (diff > 4 && diff <= 7) {
        return ExpiryCssClass.MODERATE;
      } else if (diff > 7 && diff <= 9) {
        return ExpiryCssClass.NORMAL_SINGLE_DIGIT;
      } else {
        return ExpiryCssClass.NORMAL;
      }
    } catch (err) {
      console.log(err);
    }
  }

  returnTitleText(record: CpqJob): string {
    const differences = this.dfrCpqOperations.getDifferenceInCalendarDays(record);
    let value = `The primary configuration will expire in ${differences} days`;
    if (differences < 0) {
      value = `The primary configuration is expired`;
    }
    return value;
  }

  getDifferenceInCalendarDays(record: any): number {
    return differenceInCalendarDays(
      new Date(record.PrimaryQuote.ExpirationTime), new Date());
  }

  // This function is for display value
  returnExpire(record: CpqJob) {
    const difference = this.getDifferenceInCalendarDays(record);
    let value: number | string = difference;
    if (difference > 99) {
      value = '99+';
    } else if (difference < 0) {
      value = ''; // Passing empty value to apply No Entry class
    }
    return value;
  }

  showToasterMsg() {
    this.toastr.error(
      'There is fatal error while creating project/Revision', 'Error', {
      disableTimeOut: true,
      closeButton: true
    }
    );
  }
  onClickCopy(record: CpqJob) {
    this.subscription$.push(this.cartService.getObject<DFRAclAccess>(record?.Id, { withAcl: 'true' }).subscribe({
      next: res => {
        this.spinner.show();
        const newName = `Copy of ${record?.Name}`;
        const newRecord: CpqJob = Object.assign({}, record);
        this.subscription$.push(this.cartService.copyObjectById(CpqObjectType.Opportunity, record?.Id, newName).subscribe({
          next: (results: CpqJob) => {
            newRecord.Id = results.Id;
            newRecord.Name = newName;
            const newPrimaryQuote = Object.assign({}, newRecord.PrimaryQuote);
            if (newPrimaryQuote) {
              newPrimaryQuote.WorkflowStatus = QuoteWorkflowStatus.InProgress;
              newRecord.PrimaryQuote = newPrimaryQuote;
            }
            this.projects = [newRecord, ...this.projects];
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(
              'There is fatal error while copying Project', 'Error', {
              disableTimeOut: true,
              closeButton: true
            }
            );
          }
        }));

      },
      error: err => {
        this.toastr.error(
          'There was a fatal error while getting ACL Information'
        );
      }
    }));
  }

  onClickView(project: CpqJob) {
    try {
      this.router.navigate([
        'productSelector',
        project?.Id
      ]);

    } catch (error) {
      console.log(error);
    }
  }

  onClickShare(record: CpqJob) {
    const instance = this.modalService.open(ShareComponent, {
      size: 'md',
      centered: true
    }).componentInstance;
    instance.jobID = record.Id;
    this.activeModal.close();
  }
  /**
   * Change the Primary Quote/Revision immediately and post change to backend
   * @param targetQuote
   */
  onClickFavoriteProjectCheck(targetQuote: DfrJobs): void {
    const obj = {
      IsPreferred__c: !targetQuote.IsPreferred__c
    };
    this.cartService.updateObjectById(CpqObjectType.Opportunity, targetQuote?.Id, obj).subscribe({
      next: (id) => {
        this.fetchProject();
      },
      error: (err) => {
        console.log(err);
        this.fetchProject();
        this.toastr.error(
          'There was a fatal error while setting the favorite project, please try again', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  convertStringToBoolean(value: any): boolean {
    return value?.toLowerCase() === "true";
  }

  ngOnDestroy() {
    this.projectSubscription?.unsubscribe();
    this.subscription$?.forEach(sub => sub?.unsubscribe());
  }

}
