import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CartService, CpqObjectType, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { ActionLogs, Grouping } from '@cpq-app/services/product.interface';
import { UserService } from '@cpq-app/_administration/users/user.service';
import { environment } from '@cpq-environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CadDataCds, DFRAclAccess, DFRProduct, DfrCpqOpportunity, GasCoolerCondenser, Images, OptionsCad } from '../DFR.interfaces.service';
import { DialogBoxComponent } from '@cpq-app/shared/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { GasCoolerVendors, gasCoolerService } from '@cpq-app/services/gascooler.service';

const Subcritical_Pressure = 1080;
enum DisplayModel {
  ACTIVATEDNODE_TRUE = '1',
  ACTIVATEDNODE_FALSE = '0',
  QUICKSEARCHFLOW = 'quickSearchFlow',
  ACTIVATEDNODE = 'ActiveNode',
  QUOTEPRODUCT = 'quoteproduct',
  quoteLineId = 'quoteLineId'
}

enum cdsConfigurator {
  RangeAttribute = "cds.configurator.RangeAttribute",
  Range = "range",
  String = "string"
}

declare const cds: any;

const lableAttirbuteBAC = ['Model Name', 'Airflow', 'Fan Quantity', 'Fan Power In', 'Refrigerant Charge', 'Number of Pumps', 'Total Pump Power', 'Shipping Weight', 'Operating Weight', 'Total Actual Fan Power (Assuming 90% Efficiency)'];
const ValueAttirbuteBAC = ['Name', 'Airflow', 'Fans', 'KwPerFan', 'RefrigerantCharge', 'Pumps', 'Pumphp', 'Shipweight', 'Opweight', 'Totalfanhpwitheff'];

const labelAttributeGUTNER = ['Model Name (or ID)', 'Number of Passes', 'Rated THR', 'Number of Units', 'Air Flow', 'Switch Point (if Adiabatic)', 'CO2 Mass Flow', 'Pressure Drop', 'Number of Fans', 'Fan Power In', 'Fan Power Out', 'FLA', 'MCA', 'MOCP', 'Fan Velocity', 'Sound at 3 Ft', 'Sound at 30 Ft', 'Sound Power Level', 'Fin Material', 'Coil Volume', 'MOP', 'Number of Support Anchors', 'Height', 'Width', 'Length (Nominal and with Water Manifold, if Applicable)', 'Weights(Shipping, Operation)', 'Coil Inlet Connections Qty', 'Coil Inlet Connections Diameter', 'Coil Outlet connections Qty', 'Coil Outlet connections Diameter', 'Header Diameters', 'Water Inlet and Outlet Connection Diameters (if Adiabatic)', 'Water Inlet Pressure Range (if Adiabatic)', 'Evaporation Rate at Design Conditions (if Adiabatic)', 'Design and Max Water Flow Rate (if Adiabatic)'];
const ValueAttirbuteGUTNER = ['modelName', 'numberOfPasses', 'ratedTHRInKilo', 'numberOfUnits', 'airFlow', 'switchPoint', 'cO2MassFlow', 'evaporationRate', 'numberOfFans', 'fanPowerIn', 'fanPowerOut', 'fla', 'mca', 'mocp', 'fanVelocity', 'soundAt3ft', 'soundAt30ft', 'soundPowerLevel', 'finMaterialOptionName', 'coilVolume', 'mop', 'numberOfAnchors', 'height', 'width', 'length', 'shippingWeight', 'coilInletConnectionNPT', 'coilInletConnectionDiameter', 'coilOutletConnectionNPT', 'coilOutletConnectionDiameter', 'outetHeaderDiameter', 'waterOutletConnectionDiameter', 'waterInletPresureRange', 'evaporationRate', 'maximumWaterFlow'];

const labelAttributeHeatCraft = ['Model Number', 'Application Capacity', 'Refrigerant', 'Motor type', 'Motor Horsepower', 'Motor RPM', 'Voltage', 'Frequency', 'Number of Fans', 'Fan Blade Diameter', 'FPI', 'CFM', 'FLA', 'MCA', 'MOPD', 'Unit Kw', 'Inlet Connections', 'Outlet Connections', 'Approx. Net Weight'];
const ValueAttirbuteGUTNERHeatCraft = ['MaterialNumber', 'ratedTHRInKilo', 'Refrigerant', 'MotorType', 'MotorHP', 'MotorRPM', 'Voltage', 'Frequency', 'NoOfFans', 'FanBladeDiameter', 'Fpi', 'Cfm', 'Fla', 'Mca', 'Mopd', 'UnitKW', 'InletConnections', 'OutletConnections', 'ApproxNetWeight'];


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProductDetailComponent implements OnInit, OnDestroy {
  productTypes = {
    Co2Model: "Co2Model",
    FlexModel: "FlexModel"
  }
  GasCoolerBrand = {
    BrandKey: 'aCondenserGasCoolerBrand',
    BACKey: 'avCondenserGasCoolerBrandBac',
    GuntnerKey: 'avCondenserGasCoolerBrandGuntner',
    HeatCraft: 'avCondenserGasCoolerBrandHeatcraft',
    BAC: "BAC",
    GUNTNER: "GUNTNER",
    HEATCRAFT: "HEATCRAFT"
  }

  configurationOptions = {
    aUom: 'aUom'
  }

  subscription: Subscription;
  subscription$: Subscription[] = [];
  init$: Subscription[] = [];
  projectId: string;
  revisionName: string;
  revisionId: string;
  configId: string;
  product: any;
  products: any[];
  productBaseId: number;
  productNodeId: string;
  nodeId: string;
  productDocumentURL: string;
  currentImagePath: string;
  show3DViewer = true;
  quickSerachFlow: string;
  isEorUser: boolean;
  selectedProduct: DFRProduct;
  activatedNode: string;


  activeNodeId: string;
  configSubscription: Subscription;
  saveSubscription: Subscription;
  updateSubscription: Subscription;
  aclSubscription: Subscription;
  updateTarget: string;
  productId: string;
  DISPLAY_MODEL_SPINNER = 'display model information';
  productCadId: string;
  isOptionsChange = false;
  isCadModelAvailable: boolean;
  canEdit: boolean;
  nodeGroupData: Grouping;
  panelOpenState = false;

  rackDesignCriteria;
  rootDesignCriteria;
  setPramsSubscription: Subscription;
  flexSubscriptionGuided: Subscription;
  attributesModelSubscription: Subscription;
  mainFlexSubscription: Subscription;
  setFlexDataSubscription: Subscription;
  setPakDataSubscription: Subscription;
  offSetAmbientTemp: string;
  zipCode: string;
  latitude: any;
  longitude: any;
  availableSummary: any;
  selectedNode: any;
  // nea added
  quoteLineID: any;
  productDetailData: any;
  ambientTemp: string;
  productSeries: string;
  availableProducts: any;
  availableEntries: any;
  prodcutSelectedBefore = false;
  project: any;
  cadData: CadDataCds;
  intialQuoteLineEntries = {};
  productConfigurationChanged = false;
  configurationChange: boolean;
  GasCoolerCondenser: GasCoolerCondenser;
  modelName: string;
  ratedThr: any;
  drawingPath: string;
  drawingBaseURL: string;
  selectedGasCoolorDetail: any;
  selectedGasCoolorBAC: any;
  selectedGasCoolerHeatCraft: any;
  modelDataInformation: [];
  productType: string;
  ltTotalLoad;
  mtTotalLoad;
  drawingFileName: string;
  cadParam: string;
  configOptions;
  gasCoolerCAD: string;
  AmbientTempJSON;
  subcriticalPressure = false;
  selectedGasCooler: string;
  showPrice = true;
  productSelectSubscription: Subscription;
  loaderCount = 0;
  gasCoolerbrandName: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public GasCoolerService: gasCoolerService
  ) { }

  ngOnInit(): void {
    try {
      this.spinner.show(this.DISPLAY_MODEL_SPINNER);
      this.loaderCount++;
      this.init$.push(this.route.params.subscribe({
        next: params => {
          this.processParams(params);
          this.getOpprtunityDetails();
          if (!this.cartService.getConfigData()) {
            this.getConfigOptions();
          } else {
            this.configOptions = this.cartService.getConfigData();
            this.initData();
          }
          this.isEorUser = this.userService.checkLoggedInUserIsEorUser();
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
        }
      }));
    }
    catch (err) {
      this.loaderCount--;
      if (this.loaderCount === 0) {
        this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
      }
    }
  }
  getConfigOptions() {
    this.cartService.getConfigOptions().subscribe(res => {
      this.configOptions = res;
      console.log(res);
      this.cartService.setConfigData(res);
      this.initData();
    });
  }

  initData() {
    const uom = sessionStorage.getItem('unitType');
    const unitType = uom === 'metric' ? "avUomMetric" : "avUomImperial";
    let initialOptions = {
      'aUom': unitType
    }
    let scopeElements = {
      "product": "cds-config-options-product"
    }
    cds.configurator.runtime.init(this, this.configOptions.main, initialOptions, scopeElements);
    cds.configurator.runtime.manager.onChangeHandler = function (object) {
      let self = cds.configurator.runtime;
      let scope = null;
      for (let a in self.attributeOrder) {
        if (a == 'product') {
          for (let i = 0; i < self.attributeOrder[a].length; i++) {
            let id = object.classId === "cds.configurator.SetAttributeValue" ?
              object.attribute.id : object.id;
            if (id === self.attributeOrder[a][i]) {
              scope = a;
            }
          }
        }
      }
      if (cds.configurator.runtime.callingObject) {
        cds.configurator.runtime.callingObject.onCdsConfiguratorOptionChange(scope);
      }
    }
    const querySubscription = this.getQueryParam();
  }

  getOpprtunityDetails() {
    this.spinner.show(this.DISPLAY_MODEL_SPINNER);
    this.loaderCount++;
    this.subscription$.push(this.cartService.getCpqObjects<DfrCpqOpportunity>(CpqQueryObjects.Opportunities, {
      Id: this.projectId, addonFields: DfrCpqOpportunity.addOnFields.join(', '), resolveNames: 'true'
    })
      .subscribe({
        next: (opportunity: any) => {
          this.project = opportunity;
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));

  }
  setParamsForCadData() {
    return this.cadData = {
      "success": true,
      "domain": "dfr",
      "options": this.paramsForCadData(this.modelDataInformation),
      "productId": this.productBaseId.toString().replace(/\+/g, "_"),
    }
  }
  paramsForCadData(ModelData) {
    let {
      EVAPORATOR, ENCLOSURE, SEISMIC_LEG, VOLTAGE, WEIGHT, SUCTION_LOOP, LIQUID_LOOP, LIQUID_LINE, MCA, MOPD, LT_SUCTION_LOOP,
      LT_LIQUID_LOOP, MT_LIQUID_LOOP, MT_SUCTION_LOOP, CUSTOMER, PROJECT, PROJ_VER, DATE, MODEL_NUMBER, GAS_COOLER_BRAND, LT_SUCT_FROM_STORE,
      MT_SUCT_FROM_STORE, GAS_COOLER_RETURN, GAS_COOLER_SUPPLY, LIQUID_SUPPLY_TO_STORE, PRESSURE_RELIEF_MANIFOLD, WATER_HEAT_REC_SUP, WATER_HEAT_REC_RET,
      AIR_HEAT_REC_SUP, AIR_HEAT_REC_RET, HOT_GAS_DEF_SUP, HOT_GAS_DEF_RET, GAS_COOLER
    } = this.prepareCadParamsForSubmitles(ModelData);

    let Options: OptionsCad = {
      EVAPORATOR, ENCLOSURE, SEISMIC_LEG, VOLTAGE, WEIGHT, SUCTION_LOOP, LIQUID_LOOP, LIQUID_LINE, MCA, MOPD, LT_SUCTION_LOOP,
      LT_LIQUID_LOOP, MT_LIQUID_LOOP, MT_SUCTION_LOOP, CUSTOMER, PROJECT, PROJ_VER, DATE, MODEL_NUMBER: MODEL_NUMBER.toString(),
      GAS_COOLER_BRAND, LT_SUCT_FROM_STORE, MT_SUCT_FROM_STORE, GAS_COOLER_RETURN, GAS_COOLER_SUPPLY, LIQUID_SUPPLY_TO_STORE, PRESSURE_RELIEF_MANIFOLD,
      WATER_HEAT_REC_SUP, WATER_HEAT_REC_RET, AIR_HEAT_REC_SUP, AIR_HEAT_REC_RET, HOT_GAS_DEF_SUP, HOT_GAS_DEF_RET, GAS_COOLER
    };

    switch (this.cadParam) {
      case this.GasCoolerBrand.BAC:
        this.addDynamicAttributes(Options, lableAttirbuteBAC, ValueAttirbuteBAC, this.selectedGasCoolorBAC);
        break;
      case this.GasCoolerBrand.GUNTNER:
        this.addDynamicAttributes(Options, labelAttributeGUTNER, ValueAttirbuteGUTNER, this.selectedGasCoolorDetail, true);
        break;
      default:
        this.addDynamicAttributes(Options, labelAttributeHeatCraft, ValueAttirbuteGUTNERHeatCraft, this.selectedGasCoolerHeatCraft);
    }

    if (this.productDetailData?.type === this.productTypes?.Co2Model) {
      if ('ENCLOSURE' in Options) { delete (Options).ENCLOSURE; }
    }
    else {
      if ('EVAPORATOR' in Options) { delete (Options).EVAPORATOR; }
    }
    return Options;
  }

  private prepareCadParamsForSubmitles(ModelInformation: any) {
    let ENCLOSURE = this.validAttribute("aEnclosure") ? this.getValueAttributes(cds.configurator.runtime.attributes["aEnclosure"]) : "";
    let EVAPORATOR = this.validAttribute("aEnclosure") ? this.getValueAttributes(cds.configurator.runtime.attributes["aModelType"]) : "";
    EVAPORATOR = EVAPORATOR === 'avModelTypeCdu' ? "WITHOUT_EVAPORATOR" : "WITH_EVAPORATOR";
    ENCLOSURE = ENCLOSURE === 'avEnclosureNo' ? "WITHOUT_ENCLOSURE" : "WITH_ENCLOSURE";
    let SEISMIC_LEG = this.validAttribute("aSeismicSpring") ? this.getValueAttributes(cds.configurator.runtime.attributes["aSeismicSpring"]) : "";
    let VOLTAGE = this.validAttribute("aElectricalTyp") ? this.getValueAttributes(cds.configurator.runtime.attributes["aElectricalType"]) : "";
    SEISMIC_LEG = SEISMIC_LEG === 'avSeismicSpringNo' ? "NO" : "YES";
    let WEIGHT, SUCTION_LOOP, LIQUID_LOOP, LIQUID_LINE, MCA, MOPD, LT_SUCTION_LOOP, LT_LIQUID_LOOP, MT_LIQUID_LOOP, MT_SUCTION_LOOP, CUSTOMER, PROJECT, PROJ_VER, DATE, MODEL_NUMBER = "", GAS_COOLER_BRAND, LT_SUCT_FROM_STORE, MT_SUCT_FROM_STORE, GAS_COOLER_RETURN, GAS_COOLER_SUPPLY, LIQUID_SUPPLY_TO_STORE, PRESSURE_RELIEF_MANIFOLD, WATER_HEAT_REC_SUP, WATER_HEAT_REC_RET, AIR_HEAT_REC_SUP, AIR_HEAT_REC_RET, HOT_GAS_DEF_SUP, HOT_GAS_DEF_RET, GAS_COOLER;
    this.selectedGasCoolorDetail = this.selectGasCoolerCondenser();
    this.selectedGasCoolorBAC = this.selectGasCoolerBac();
    this.selectedGasCoolerHeatCraft = this.selectGasCoolerBac();
    GAS_COOLER_BRAND = this.cadParam;
    if (ModelInformation && ModelInformation?.length > 0) {
      let liquid_line = ModelInformation?.find(x => x.Id === 'liquid_line');
      let weight = ModelInformation?.find(x => x.Id === 'weight');
      let mca = ModelInformation?.find(x => x.Id === 'MCA');
      let mopd = ModelInformation?.find(x => x.Id === 'MOPD');
      let ltSuctionLoop = ModelInformation?.find(x => x.Id === 'lt_sunction_loop');
      let mtSuctionLoop = ModelInformation?.find(x => x.Id === 'mt_sunction_loop');
      let ltLiquidLoop = ModelInformation?.find(x => x.Id === 'lt_liquid_loop');
      let mtLiquidLoop = ModelInformation?.find(x => x.Id === 'mt_liquid_loop');
      let suctionLoop = ModelInformation?.find(x => x.Id === 'suction_loop');
      let liquidLoop = ModelInformation?.find(x => x.Id === 'liquid_loop');
      let ltSuctionFromLoop = ModelInformation?.find(x => x.Id === 'lt_suction_from_store');
      let mtSuctionFromLoop = ModelInformation?.find(x => x.Id === 'mt_suction_from_store');
      let gasCoolerReturn = ModelInformation?.find(x => x.Id === 'gas_cooler_return');
      let gasCoolerSupply = ModelInformation?.find(x => x.Id === 'gas_cooler_supply');
      let liquidSupplyStore = ModelInformation?.find(x => x.Id === 'liquid_supply_to_store');
      let pressureManifold = ModelInformation?.find(x => x.Id === 'pressure_relief_manifold');
      let waterHeatSupply = ModelInformation?.find(x => x.Id === 'water_heat_reclaim_supply');
      let waterHeatReturn = ModelInformation?.find(x => x.Id === 'water_heat_reclaim_return');
      let airHeatSupply = ModelInformation?.find(x => x.Id === 'air_heat_reclaim_supply');
      let airHeatReturn = ModelInformation?.find(x => x.Id === 'air_heat_reclaim_return');
      let hotGasSupply = ModelInformation?.find(x => x.Id === 'hot_gas_defrost_supply');
      let hotGasReturn = ModelInformation?.find(x => x.Id === 'hot_gas_defrost_return');
      WEIGHT = this.appendUnitIfPresent(weight);
      SUCTION_LOOP = this.appendUnitIfPresent(suctionLoop);
      LIQUID_LOOP = this.appendUnitIfPresent(liquidLoop);
      LIQUID_LINE = this.appendUnitIfPresent(liquid_line);
      MCA = this.appendUnitIfPresent(mca);
      MOPD = this.appendUnitIfPresent(mopd);
      LT_SUCTION_LOOP = this.appendUnitIfPresent(ltSuctionLoop);
      LT_LIQUID_LOOP = this.appendUnitIfPresent(ltLiquidLoop);
      MT_LIQUID_LOOP = this.appendUnitIfPresent(mtLiquidLoop);
      MT_SUCTION_LOOP = this.appendUnitIfPresent(mtSuctionLoop);
      CUSTOMER = this.project?.Account?.Name;
      PROJECT = this.project?.Name;
      PROJ_VER = this.revisionName;
      LT_SUCT_FROM_STORE = ltSuctionFromLoop?.Value || "NA";
      MT_SUCT_FROM_STORE = mtSuctionFromLoop?.Value || "NA";
      GAS_COOLER_RETURN = gasCoolerReturn?.Value || "NA";
      GAS_COOLER_SUPPLY = gasCoolerSupply?.Value || "NA";
      LIQUID_SUPPLY_TO_STORE = liquidSupplyStore?.Value || "NA";
      PRESSURE_RELIEF_MANIFOLD = pressureManifold?.Value || "NA";
      WATER_HEAT_REC_SUP = waterHeatSupply?.Value || "NA";
      WATER_HEAT_REC_RET = waterHeatReturn?.Value || "NA";
      AIR_HEAT_REC_SUP = airHeatSupply?.Value || "NA";
      AIR_HEAT_REC_RET = airHeatReturn?.Value || "NA";
      HOT_GAS_DEF_SUP = hotGasSupply?.Value || "NA";
      HOT_GAS_DEF_RET = hotGasReturn?.Value || "NA";
      GAS_COOLER = this.gasCoolerCAD;
      DATE = new Date().toLocaleDateString();
      MODEL_NUMBER = this.productBaseId.toString().replace(/\+/g, "_");
    }

    return { EVAPORATOR, ENCLOSURE, SEISMIC_LEG, VOLTAGE, WEIGHT, SUCTION_LOOP, LIQUID_LOOP, LIQUID_LINE, MCA, MOPD, LT_SUCTION_LOOP, LT_LIQUID_LOOP, MT_LIQUID_LOOP, MT_SUCTION_LOOP, CUSTOMER, PROJECT, PROJ_VER, DATE, MODEL_NUMBER, GAS_COOLER_BRAND, LT_SUCT_FROM_STORE, MT_SUCT_FROM_STORE, GAS_COOLER_RETURN, GAS_COOLER_SUPPLY, LIQUID_SUPPLY_TO_STORE, PRESSURE_RELIEF_MANIFOLD, WATER_HEAT_REC_SUP, WATER_HEAT_REC_RET, AIR_HEAT_REC_SUP, AIR_HEAT_REC_RET, HOT_GAS_DEF_SUP, HOT_GAS_DEF_RET, GAS_COOLER };
  }

  validAttribute(attribute) {
    let inputEntries = cds.configurator.runtime.attributes[attribute];
    return typeof inputEntries !== 'undefined' && inputEntries !== null ? true : false;
  }

  selectProduct() {
    if (this.availableProducts) {
      this.availableProducts.forEach((product) => {
        if (product?.Selected) {
          this.prodcutSelectedBefore = true;
        }
      });
      if (this.prodcutSelectedBefore) {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
          data: { message: 'Product already selected.Do you want to select this product?' },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            // if clicked Yes
            this.spinner.show(this.DISPLAY_MODEL_SPINNER);
            this.loaderCount++;
            let paramOptions = {
              "ProductId": this.productBaseId
            }
            this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, this.quoteLineID, paramOptions)
              .subscribe({
                next: (quoteLine: any) => {
                  this.getProductDetailResult(this.quoteLineID, true);
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
                  }
                },
                error: err => {
                  this.loaderCount--;
                  if (this.loaderCount === 0) {
                    this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
                  }
                  this.toastr.error(
                    'There is fatal error while updated product card', 'Error', {
                    disableTimeOut: true,
                    closeButton: true
                  }
                  );
                }
              }));
          } else {
            console.log('You clicked No!');
          }
        });
      } else {
        this.spinner.show(this.DISPLAY_MODEL_SPINNER);
        this.loaderCount++;
        let paramOptions = {
          "ProductId": this.productBaseId
        }
        this.subscription$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, this.quoteLineID, paramOptions)
          .subscribe({
            next: (quoteLine: any) => {
              this.getProductDetailResult(this.quoteLineID, true);
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
              }
            },
            error: err => {
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
              }
              this.toastr.error(
                'There is fatal error while updated product card', 'Error', {
                disableTimeOut: true,
                closeButton: true
              }
              );
            }
          }));
      }
    }
  }

  onCdsConfiguratorOptionChange(ConfiguratorType) {
    if (ConfiguratorType == 'product') {
      this.setParamsForCadData();
      this.configurationChange = false;
      this.checkOnChangeGasCoolerCondenser();
    }
  }

  private checkOnChangeGasCoolerCondenser() {
    let checkFinOptions = null;
    if (cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'] != undefined) {
      if (cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'].getValue()) {
        checkFinOptions = cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'].getValue()[0].id;
        if (checkFinOptions != undefined) {
          if (this.GasCoolerCondenser && this.GasCoolerCondenser?.data) {
            if (this.GasCoolerCondenser?.data?.length > 0) {
              this.gasCoolerCAD = 'YES';
              let selectedGasCooler;
              switch (this.gasCoolerbrandName) {
                case this.GasCoolerBrand.GuntnerKey:
                  selectedGasCooler = this.GasCoolerCondenser?.data?.find(x => x.finMaterialOptionName === checkFinOptions);
                  this.modelName = selectedGasCooler?.modelName;
                  this.ratedThr = selectedGasCooler?.ratedTHRInKilo;
                  this.cadParam = this.GasCoolerBrand.GUNTNER;
                  if (selectedGasCooler?.drawingName) {
                    this.drawingPath = this.GasCoolerService.getGasCoolerFileUrl(selectedGasCooler?.drawingName, GasCoolerVendors.Guntner);
                    this.drawingFileName = selectedGasCooler?.drawingName;
                  }
                  break;
                case this.GasCoolerBrand.BACKey:
                  selectedGasCooler = this.GasCoolerCondenser?.data[0];
                  this.modelName = selectedGasCooler?.Name;
                  this.ratedThr = selectedGasCooler?.ProductTotalHeatRejection;
                  this.cadParam = this.GasCoolerBrand.BAC;
                  if (selectedGasCooler?.SubmittalFileLink) {
                    this.drawingPath = selectedGasCooler.SubmittalFileLink;
                    this.drawingFileName = selectedGasCooler?.drawingName;
                  }
                  break;
                default:
                  selectedGasCooler = this.GasCoolerCondenser?.data[0];
                  this.modelName = selectedGasCooler?.MaterialNumber;
                  this.ratedThr = selectedGasCooler?.ratedTHRInKilo;
                  this.cadParam = this.GasCoolerBrand.HEATCRAFT;
                  if (selectedGasCooler?.drawingName) {
                    this.drawingPath = this.GasCoolerService.getGasCoolerFileUrl(selectedGasCooler?.drawingName, GasCoolerVendors.Guntner);
                    this.drawingFileName = selectedGasCooler?.drawingName;
                  }
                  break;
              }
            }
            else {
              this.gasCoolerCAD = 'NO';
            }
          }
        }
      }
    }
  }
  openGasCoolerCondenserSubmittalDrawing() {
    if (this.drawingPath) {
      const actionGasCooler: ActionLogs = {
        entity: 'Gas Cooler',
        name: 'Gas Cooler / Condenser Submittal',
        id: this.revisionId
      }
      this.spinner.show(this.DISPLAY_MODEL_SPINNER);
      this.loaderCount++;
      this.subscription$.push(this.cartService.logActions(actionGasCooler)
        .subscribe({
          next: () => {
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
            }
          },
          complete: () => {
            switch (this.gasCoolerbrandName) {
              case this.GasCoolerBrand.BACKey:
                this.GasCoolerService.downloadGasCoolerBACFile(this.drawingPath);
                break;
              case this.GasCoolerBrand.GuntnerKey:
                this.GasCoolerService.getGasCoolerFileDownload(this.drawingFileName, GasCoolerVendors.Guntner);
                break;
              default:
                this.GasCoolerService.downloadDrawing(this.drawingPath);
                break;
            }
          },
          error: err => {
            this.loaderCount--;
            if (this.loaderCount === 0) {
              this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
            }
            this.toastr.error(
              'There is fatal error while capture logs', 'Error', {
            }
            );
          }
        }));
    }
  }


  private getQueryParam() {
    this.spinner.show(this.DISPLAY_MODEL_SPINNER);
    this.loaderCount++;
    return this.activatedRoute.queryParamMap.subscribe(
      queryParamMap => {
        this.quoteLineID = queryParamMap.get(DisplayModel.quoteLineId);
        this.getProductDetailResult(this.quoteLineID, undefined, true)
        // this.getActivatedNodeId(this.activatedNode);
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
        }
      },
      err => {
        this.loaderCount--;
        if (this.loaderCount === 0) {
          this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
        };
        this.toastr.error(
          'There was an error while fetching Model Information', 'Error', {
          disableTimeOut: true,
          closeButton: true
        });
      }
    );
  }

  getProductDetailResult(quoteId, onProductSelect?, autoSave?) {
    this.spinner.show(this.DISPLAY_MODEL_SPINNER);
    this.loaderCount++;
    this.init$.push(this.cartService.getCPQObjectById(CpqObjectType.QuoteLine, quoteId)
      .subscribe({
        next: (quoteLine: any) => {
          this.availableProducts = quoteLine?.AvailableProducts;
          this.availableEntries = quoteLine?.Entries;
          this.gasCoolerbrandName = this.getBrandFromEntries();

          const filteredProducts = quoteLine?.AvailableProducts.filter(product => product.ProductId === this.productBaseId);
          if (filteredProducts?.length) {
            this.productDetailData = filteredProducts[0];
            this.productType = this.productDetailData?.type;
            this.productDetailData.BaseSellingPrice = quoteLine?.BaseSellingPrice;
            this.productDetailData.TotalSellingPrice = quoteLine?.TotalSellingPrice;
            this.GasCoolerCondenser = quoteLine?.GasCoolerCondenser;
            this.gasCoolerCAD = (this.GasCoolerCondenser?.data?.length > 0 && this.productDetailData.Selected) ? 'YES' : 'NO';
            this.updateModelsValue();
            this.subcriticalPressure = this.productDetailData?.GasCoolerPsiA < Subcritical_Pressure;

            this.setModelConfiguration(quoteLine, onProductSelect, autoSave);
          }

          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
          this.toastr.error('There is a fatal error while fetching revision', 'Error');
        },
        complete: () => {
          this.configurationChange = true;
        }

      }));
  }

  checkOnRelatedTHR() {
    if (this.GasCoolerCondenser && this.GasCoolerCondenser?.data) {
      if (this.GasCoolerCondenser?.data?.length > 0) {
        let selectedGasCoolor = this.GasCoolerCondenser?.data?.find(x => x.modelName === this.modelName);
        this.ratedThr = selectedGasCoolor?.ratedTHRInKilo ? selectedGasCoolor?.ratedTHRInKilo : selectedGasCoolor?.ProductTotalHeatRejection;
      }
    }
  }

  setModelConfiguration(quoteLine, onProductSelect?, autoSave?) {
    let newQuoteLineEntriesProduct = [];
    let ProductNewQuoteLineEntries = [];
    let EntriesDesignRack = [];
    let productEntries = cds.configurator.runtime.attributeOrder.product;
    let DesignEntries = cds.configurator.runtime.attributeOrder.design;
    let RackEntries = cds.configurator.runtime.attributeOrder.rack;
    if (DesignEntries && RackEntries && productEntries) {
      EntriesDesignRack = [...DesignEntries, ...RackEntries, ...productEntries];
      EntriesDesignRack.unshift("aProductFamily", "aProductSeries", "aRefrigerationType");
      EntriesDesignRack?.forEach((product) => {
        newQuoteLineEntriesProduct.push(quoteLine?.Entries.filter(x => x.OptionGroupId === product));
      });
      this.mtTotalLoad = quoteLine?.Entries.find(x => x.OptionGroupId === 'aMtTotalLoad');
      this.ltTotalLoad = quoteLine?.Entries.find(x => x.OptionGroupId === 'aLtTotalLoad');
      newQuoteLineEntriesProduct = newQuoteLineEntriesProduct?.filter(x => x.length > 0);
      newQuoteLineEntriesProduct.forEach(option => {
        ProductNewQuoteLineEntries.push(option[0]);
      });
      if (this.productDetailData?.Selected) {
        // this.showPrice = true;
        this.setConfigurationValues(ProductNewQuoteLineEntries, onProductSelect, autoSave);
      }
      // DefaultSelctionForOptions(ProductNewQuoteLineEntries);
      let GasCoolerValues = cds.configurator.runtime.attributes['aCondenserGasCoolerBrand']?.values;
      let GasCooler = GasCoolerValues.find(x => x && x.isSelected);
      this.selectedGasCooler = GasCooler?.label;
      if (this.productBaseId) {
        let productId = this.productBaseId.toString().replace(/\+/g, "_");
        this.spinner.show(this.DISPLAY_MODEL_SPINNER);
        this.loaderCount++;
        this.subscription$.push(this.cartService.getQuoteLineProductById(this.quoteLineID, this.productBaseId.toString())
          .subscribe({
            next: (modelData: any) => {
              this.modelDataInformation = modelData?.ModelInformation;
              this.setParamsForCadData();
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
              }
            },
            error: err => {
              this.loaderCount--;
              if (this.loaderCount === 0) {
                this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
              }
              this.toastr.error(
                'There is fatal error while fetching product data', 'Error', {
                disableTimeOut: true,
                closeButton: true
              }
              );
            }
          }));
      }
    }
  }

  setConfigurationValues(productQuoteLineEntries, onProductSelect?, autoSave?) {
    productQuoteLineEntries.forEach(option => {
      if (option.OptionType === cdsConfigurator.Range || option.OptionType === cdsConfigurator.String) {
        if (cds.configurator.runtime.attributes[option.OptionGroupId] != undefined)
          cds.configurator.runtime.attributes[option.OptionGroupId].setValue(option.ValueDouble);
      }
      else {
        if (cds.configurator.runtime.attributes[option.OptionGroupId] != undefined) {
          const optionVal = cds.configurator.runtime.attributes[option.OptionGroupId].getValue();
          let optionValue = optionVal && optionVal[0].isSelected ? optionVal[0].id : null;
          if(option.OptionGroupId === this.GasCoolerBrand.BrandKey && this.productDetailData?.type === this.productTypes?.Co2Model) {
            cds.configurator.runtime.attributes[option.OptionGroupId].setValue(this.GasCoolerBrand.GuntnerKey);
          } else if (optionValue !== option.ValueString) {
            cds.configurator.runtime.attributes[option.OptionGroupId].setValue(option.ValueString);
          }
        }
      }
    });
    if (onProductSelect || autoSave) {
      this.showPrice = false;
      this.saveConfigurationDetail(onProductSelect, autoSave);
    }
  }

  addDynamicAttributes(Options: OptionsCad, labels: string[], values: string[], selectedGasCooler: any, isGuntner: boolean = false) {
    if (!Array.isArray(labels) || !Array.isArray(values)) {
      throw new Error('Labels and values must be arrays');
    }

    if (!selectedGasCooler) {
      console.error('selectedGasCooler is not defined');
      return;
    }

    let attributeLableIndex = 0;
    let attributeValueIndex = 0;

    labels.forEach((value, index) => {
      const attributeName = `ATTRI_NAME_${attributeLableIndex + 1}`;
      attributeLableIndex += 1;
      Options[attributeName] = value;
    });

    values.forEach((key, index) => {
      const attributeValue = `ATTRI_VALUE_${attributeValueIndex + 1}`;
      attributeValueIndex += 1;
      if (isGuntner && key === 'finMaterialOptionName') {
        Options[attributeValue] = selectedGasCooler?.finMaterialOptionName === 'avCondenserGasCoolerFinMaterialAluminum'
          ? 'Aluminum'
          : selectedGasCooler?.finMaterialOptionName === 'avCondenserGasCoolerFinMaterialEpoxyCoated'
            ? 'Epoxy-Coated'
            : "NA";
      } else {
        Options[attributeValue] = selectedGasCooler[key] || 'NA';
      }
    });

    this.checkWeatherAccessory(attributeLableIndex, attributeValueIndex, Options);
  }

  checkWeatherAccessory(lable, value, Options: OptionsCad) {
    let checkWeatherOptions = null;
    if (cds.configurator.runtime.attributes['aWeatherAccessory'] != undefined) {
      if (cds.configurator.runtime.attributes['aWeatherAccessory'].getValue()) {
        checkWeatherOptions = cds.configurator.runtime.attributes['aWeatherAccessory'].getValue()[0].id;
        if (checkWeatherOptions != undefined) {
          const attributeName = `ATTRI_NAME_${lable + 1}`;
          Options[attributeName] = 'Weather Accessory';
          const attributeValue = `ATTRI_VALUE_${value + 1}`;
          Options[attributeValue] = checkWeatherOptions === 'avWeatherAccessoryStandard'
            ? 'Standard'
            : checkWeatherOptions === 'avWeatherAccessoryGcBypass'
              ? 'GC Bypass'
              : 'Cold Weather Kit';
        }
      }
    }
  }


  private processParams(params: Params) {
    if (params?.projectId) {
      this.projectId = params.projectId;
    }
    if (params?.revisionName) {
      this.revisionName = params.revisionName;
    }
    if (params?.revisionId) {
      this.revisionId = params.revisionId;
    }
    if (params?.configId) {
      this.configId = params?.configId;
    }
    if (params?.productId) {
      this.productBaseId = params?.productId;
    }
    if (params?.nodeId) {
      this.nodeId = params?.nodeId;
    }
    if (params?.productCadId) {
      this.productCadId = params?.productCadId
    }
    // new added
    if (params?.ambientTemp) {
      this.ambientTemp = params?.ambientTemp
    }
  }

  navigateBackToRecommendations() {
    this.router.navigate([
      'product/selection',
      this.revisionName,
      this.projectId,
      this.revisionId,
      this.configId, this.ambientTemp

    ]);
  }

  navigateBackToSearchProduct() {
    this.router.navigate(['model/results', this.projectId, this.revisionId, this.configId], { queryParams: { search: '' } });
  }

  showImages(image: Images) {
    this.show3DViewer = false;
    this.currentImagePath = "";
    return this.currentImagePath = `${environment.cds.ImageBaseUrl}/domains/dfr/images/${image}`;
  }

  displayThumbnail(image: Images) {
    return `${environment.cds.ImageBaseUrl}/domains/dfr/images/${image}`;
  }

  toggle3D() {
    return this.show3DViewer = true;
  }

  selectGasCoolerCondenser() {
    let checkFinOptions = null;
    if (cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'] != undefined) {
      if (cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'].getValue()) {
        checkFinOptions = cds.configurator.runtime.attributes['aCondenserGasCoolerFinMaterial'].getValue()[0].id;
        if (checkFinOptions != undefined) {
          if (this.GasCoolerCondenser && this.GasCoolerCondenser?.data) {
            if (this.GasCoolerCondenser?.data?.length > 0) {
              let selectedGasCoolor = this.GasCoolerCondenser?.data?.find(x => x.finMaterialOptionName === checkFinOptions);
              if (selectedGasCoolor) {
                return selectedGasCoolor;
              } else {
                return [];
              }
            }
          }
        }
      }
    }
  }

  selectGasCoolerBac() {
    if (this.GasCoolerCondenser && this.GasCoolerCondenser?.data) {
      if (this.GasCoolerCondenser?.data?.length == 1) {
        let selectedGasCoolor = this.GasCoolerCondenser.data[0];
        if (selectedGasCoolor) {
          return selectedGasCoolor;
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
  }

  goBacktoProjectDetails() {
    try {
      this.router.navigate([
        'productSelector',
        this.projectId
      ]);

    } catch (error) {
      console.log(error);
    }
  }
  checkCadModelAvailable(isCadModelAvailable): boolean {
    if (isCadModelAvailable) {
      if (this.product && this.product.images.length > 0) {
        this.show3DViewer = true;
        this.currentImagePath = `${environment.fpx.BlobDFRStorageURL}/${this.product.images[0]?.attachment}`;
      }
    }
    else {
      this.show3DViewer = true;
    }
    return this.isCadModelAvailable = isCadModelAvailable;
  }

  checkisActionChange(event: ActionLogs) {
    event.id = this.revisionId;
    this.spinner.show(this.DISPLAY_MODEL_SPINNER);
    this.loaderCount++;
    this.subscription$.push(this.cartService.logActions(event)
      .subscribe({
        next: () => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while capture logs', 'Error', {
          }
          );
        }
      }));
  }

  getACLAccessDetails(Id: string) {
    this.aclSubscription = this.cartService.getObject<DFRAclAccess>(Id, { withAcl: 'true' }).subscribe({
      next: res => {
        this.canEdit = res?.fpRules.acl.canEdit;
        console.log('this.canEdit', this.canEdit);
      },
      error: err => {
        this.toastr.error(
          'There was a fatal error while getting ACL Information'
        );
      }
    });
  }

  saveConfigurationDetail(isProductSelected?, autoSave?) {
    this.spinner.show(this.DISPLAY_MODEL_SPINNER);
    this.loaderCount++;
    let paramOptions = {
      "Entries": this.paramForQuoteLineEntries(),
    }
    this.init$.push(this.cartService.updateObjectById(CpqObjectType.QuoteLine, this.quoteLineID, paramOptions)
      .subscribe({
        next: (quoteLine: any) => {
          this.availableProducts = quoteLine?.AvailableProducts;
          let filteredProducts = quoteLine?.AvailableProducts.filter(product => product.ProductId === this.productBaseId);
          if (filteredProducts?.length) {
            this.productDetailData = filteredProducts[0];
            this.productDetailData.BaseSellingPrice = quoteLine?.BaseSellingPrice;
            this.productDetailData.TotalSellingPrice = quoteLine?.TotalSellingPrice;
            this.GasCoolerCondenser = quoteLine?.GasCoolerCondenser;
            this.gasCoolerCAD = (this.GasCoolerCondenser?.data?.length > 0 && this.productDetailData.Selected) ? 'YES' : 'NO';
            this.setParamsForCadData();
            this.updateModelsValue();
          }
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
          this.configurationChange = true;
          if (isProductSelected || autoSave) {
            this.showPrice = true;
          } else {
            this.openPopUpConfirmation();
            this.toastr.success(
              'Model Configuration Saved',
            );
          }
        },
        error: err => {
          this.loaderCount--;
          if (this.loaderCount === 0) {
            this.spinner.hide(this.DISPLAY_MODEL_SPINNER);
          }
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  paramForQuoteLineEntries() {
    let orderProductGroup = cds.configurator.runtime.manager.getValue();
    let Entries = {};
    for (let product in orderProductGroup) {
        Entries[product] = this.getValueAttributes(cds.configurator.runtime.attributes[product]);
    }
    if (!Entries.hasOwnProperty(this.configurationOptions.aUom)) {
      const uomEntry = cds.configurator.runtime.attributes[this.configurationOptions.aUom].getValue()[0]?.id;
      Entries = { ...Entries, aUom: uomEntry }
    }
    return Entries;
  }

  updateModelsValue() {
    const gasCoolerData = this.GasCoolerCondenser?.data?.[0];
    const commonDataAssignments = () => {
      this.modelName = this.GasCoolerCondenser?.name;
      this.drawingFileName = gasCoolerData?.drawingName;
      this.drawingPath = gasCoolerData?.SubmittalFileLink;
    };

    switch (this.gasCoolerbrandName) {
      case this.GasCoolerBrand.BACKey:
        commonDataAssignments();
        this.ratedThr = gasCoolerData?.ProductTotalHeatRejection;
        this.cadParam = this.GasCoolerBrand.BAC;
        break;
      case this.GasCoolerBrand.GuntnerKey:
        this.drawingBaseURL = environment.B2CConfigs.BackendURL;
        this.cadParam = this.GasCoolerBrand.GUNTNER;
        this.modelName = this.GasCoolerCondenser?.name;
        this.drawingFileName = this.GasCoolerCondenser?.fileName;

        this.drawingPath = this.GasCoolerService.getGasCoolerFileUrl(this.GasCoolerCondenser?.fileName, GasCoolerVendors.Guntner)
          || gasCoolerData?.SubmittalFileLink;

        if (this.modelName) {
          this.checkOnRelatedTHR();
        }
        if (!this.drawingPath && gasCoolerData?.drawingName) {
          this.drawingPath = this.GasCoolerService.getGasCoolerFileUrl(gasCoolerData.drawingName, GasCoolerVendors.Guntner);
          this.drawingFileName = gasCoolerData.drawingName;
        }
        break;
      default:
        commonDataAssignments();
        this.ratedThr = gasCoolerData?.ratedTHRInKilo;
        this.cadParam = this.GasCoolerBrand.HEATCRAFT;
        break;
    }
  }

  getBrandFromEntries() {
    const entry = this.availableEntries.find(entry => entry.OptionGroupId === this.GasCoolerBrand.BrandKey);
    return entry ? entry.ValueString : null;
  }

  getValueAttributes(attribute) {
    if (typeof attribute !== 'undefined' && attribute !== null) {
      const value = attribute.getValue();
      if (Array.isArray(value) && value.length > 0) {
        return value[0].id;
      } else {
        return value;
      }
    }
  }

  openPopUpConfirmation() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      data: {
        option1: 'Continue Configuring',
        option2: 'Go to Projects Details page'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // if clicked Yes
        this.goBacktoProjectDetails();
      } else {
        // on same page
      }
    });
  }

  appendUnitIfPresent(entry) {
    if (!entry || typeof entry !== 'object') {
      return 'N/A';
    }
    const value = entry?.Value;
    if (typeof value === 'undefined' || value === null || value === '' || value === 'N/A') {
      return 'N/A';
    }
    if (typeof value !== 'string' && typeof value !== 'number') {
      return 'N/A';
    }
    const unit = entry?.Unit;
    if (unit && typeof unit === 'string') {
      return `${value} ${unit}`;
    }
    return value.toString();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription$.forEach(sub => sub.unsubscribe());
    this.init$.forEach(sub => sub.unsubscribe());
    this.configSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.updateSubscription?.unsubscribe();
    this.setPramsSubscription?.unsubscribe();
    this.mainFlexSubscription?.unsubscribe();
    this.flexSubscriptionGuided?.unsubscribe();
    if (this.productSelectSubscription) {
      this.productSelectSubscription.unsubscribe();
    }
  }
}
