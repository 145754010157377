<div class="modal-header preview-header">
    <div class="header">Compare Products</div>
    <div>
        <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true"><i class="fa fa-times mr-2" aria-hidden="true"></i></span>
        </button>
    </div>
</div>

<div class="modal-body preview" id="preview">
    <div class="container_Compare_products">
        <table class="compare-table">
            <thead>
                <tr>
                    <th class="product-label">Product Comparison</th>
                    <th *ngFor="let product of compareProductData">
                        <img class="product-img" [src]="product.imageUrl"
                            onerror="this.onerror=null;this.src='../assets/images/dfr/no_image.png';"
                            alt="{{ product.ProductId }}">
                        <h3 class="modelname">{{ product?.ProductId }}</h3>
                        <div class="select-product-block radio-with-Icon">
                            <div>
                                <div class="radioOption-Item"
                                    [ngClass]="{'radio-with-Icon-internal': product?.Product?.Selected?.Value === true}">
                                    <input type="radio" name="ProductSelect" id="{{product?.ProductId}}" value="true"
                                        class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false"
                                        [checked]="product?.Product?.Selected?.Value === true">
                                    <label for="{{product?.ProductId}}">
                                        <span *ngIf="product?.Product?.Selected?.Value === true">Selected</span>
                                        <span *ngIf="product?.Product?.Selected?.Value !== true">Select</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <!-- table body-->
            <tbody *ngIf="compareProductData">
                <ng-container *ngFor="let compareProduct of compareProductLable; let i = index">
                    <tr class="heading" *ngIf="i != 0">
                        <td [attr.colspan]="compareProduct.Attributes.length + 1">
                            <span>{{compareProduct.TableName}}</span>
                        </td>
                    </tr>
                    <ng-container>
                        <tr *ngFor="let category of compareProduct.Attributes">
                            <td class="label">{{ category.Label }}</td>
                            <td *ngFor="let product of compareProductData">
                                <div class="spec" style="white-space: pre-wrap;">{{
                                    getValueFromPath(category,product) }}</div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>